// Sorteio.js
import React, { useEffect, useState } from 'react';
import { useBingo } from './../context/BingoContext';

import BolasSorteadas from '../components/BolasSorteadas'
import PremioSorteio from '../components/PremioSorteio'
import RankingUser from '../components/Ranking'
import PremiadorAnimado from '../components/animacao/SaiuPremio'
import UltimasBolas from '../components/UltimasBolas.js'


import ComponentBall from '../components/animacao/ComponentBall'
// import backgroundImage from './../images/background.png';
import backgroundImage from './../images/back.jpg';
import backgroundImageContainer from './../images/backgroundPremios.png';


import backgroundImagePremios from './../images/backgroundPremios.png';
import coutImageBoll from './../images/bls/1.png';


import Confetti from 'react-confetti';
import CustomPlayer from './../components/anuncios/anuncios';
import { Container } from './rotacao';
import ProximaRodada from '../components/anuncios/proximaRodada.js';
import styled from 'styled-components';


const Sorteio = () => {
  const { message, updateBingoData, primeiroPremio, segundoPremio, terceiroPremio, numerosSorteados, anuncios } = useBingo();
  const [quantidadeNumeros, setQuantidadeNumeros] = useState(0);
  const [showPremiadorAnimado, setShowPremiadorAnimado] = useState(false);
  const [animateCount, setAnimateCount] = useState(false);
  const [anunciosExp, setAnunciosExp] = useState(false);
  const [mostrar1, setMostrar1] = useState(false);
  const [mostrar2, setMostrar2] = useState(false);
  const [mostrar3, setMostrar3] = useState(false);


  useEffect(() => {
    // Conta a quantidade de números sorteados
    setQuantidadeNumeros(numerosSorteados.length);
    // Ativa a animação
    setAnimateCount(true);
    // Desativa a animação após 500ms
    setTimeout(() => {
      setAnimateCount(false);
    }, 500);
  }, [numerosSorteados]);

  useEffect(() => {
    if (message === 'anuncioStart') {
      setTimeout(() => {
        setAnunciosExp(true);
      }, 2000);
    } else {
      setAnunciosExp(false);
    }
  }, [message, anuncios]);

  useEffect(() => {
    if (mostrar1 === true) {
      let timeout1;
      if (primeiroPremio && primeiroPremio.length > 0) {
        setTimeout(() => {
          setShowPremiadorAnimado(true);
        }, 2000);
        setTimeout(() => {
          setMostrar1(false);
          setMostrar2(true)
          setShowPremiadorAnimado(true);
        }, 5000);
        return () => clearTimeout(timeout1);
      }
    }
  }, [primeiroPremio, mostrar1]);

  useEffect(() => {
    if (mostrar2 === true) {
      let timeout2;
      if (segundoPremio && segundoPremio.length > 0) {
        setTimeout(() => {
          setShowPremiadorAnimado(true);
        }, 2000);
        setTimeout(() => {
          setMostrar2(false);
          setMostrar3(true)
          setShowPremiadorAnimado(true);
        }, 5000);
        return () => clearTimeout(timeout2);
      }
    }
  }, [segundoPremio, mostrar2]);

  useEffect(() => {
    if (mostrar3 === true) {
      let timeout3;
      if (terceiroPremio && terceiroPremio.length > 0) {
        setTimeout(() => {
          setShowPremiadorAnimado(true);
        }, 2000);
        setTimeout(() => {
          setMostrar2(false);
          setMostrar3(true)
          setShowPremiadorAnimado(true);
        }, 5000);
        return () => clearTimeout(timeout3);
      }
    }
  }, [terceiroPremio, mostrar3]);

  // useEffect(() => {
  //   if (primeiroPremio.length > 0 || segundoPremio.length > 0 || terceiroPremio.length > 0) {
  //     setTimeout(() => {
  //       setShowPremiadorAnimado(true);
  //       setTimeout(() => {
  //         setShowPremiadorAnimado(false);
  //       }, 2000);
  //     }, 2000);
  //   }
  // }, [primeiroPremio, segundoPremio, terceiroPremio]);

  useEffect(() => {
    window.Echo.channel('atualizar_rifa')
      .listen('AtualizarDados', (e) => {
        // setMessage(e.numerosSorteados)
        updateBingoData({
          message: e.message,
          numerosSorteados: e.numerosSorteados,
          rankingDez: e.rankingDez,
          premios: e.premios,
          primeiroPremio: e.primeiroPremio,
          segundoPremio: e.segundoPremio,
          terceiroPremio: e.terceiroPremio,
          anuncios: e.anuncios,
        });
      });

    return () => {
      //   socket.disconnect(); // Desconectar o socket quando o componente for desmontado
    };
  }, [updateBingoData]);



  // const renderNextMedia = () => {
  //   if (mediaQueue.length > 0) {
  //     const media = mediaQueue[0];
  //     const { type, src } = media;
  //     if (type === 'video') {
  //       return <video src={src} controls autoPlay />;
  //     } else if (type === 'image') {
  //       return <img src={src} alt="Imagem" />;
  //     }
  //   }
  //   return null;
  // };

  return (
    // <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
    <Container >
      <div style={{
        position: 'absolute', top: 0, right: '0vw', width: '100vw', height: '100vh', backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImageContainer})`,
        margin: '0 auto', borderRadius: '0px', overflow: 'hidden'
      }}>
        <div >

          {showPremiadorAnimado && <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={200}
            gravity={0.5}
            size={165}
            colors={['#ff0000', '#00ff00', '#0000ff']}
          />}
          {anunciosExp && anuncios &&
            <div style={{ display: 'flex', height: '100vh', width: '100vw' }}>
              <div style={{ flex: '0 0 20%', backgroundColor: 'lightblue' }}>
                <CustomPlayer />
              </div>
              <div style={{ flex: '1', backgroundColor: '#280c46', height: '100vh' }}>
                <ProximaRodada />
              </div>
            </div>

          }
          {/* VERSAO 3 */}
          {anunciosExp === false &&
            <>
              <div style={{ width: '20vw', height: '100vh', position: 'absolute', top: 0, left: 0 }}>
                <RankingUser />
              </div>
              <div style={{
                width: '65vw', height: '70vh', position: 'absolute', top: 10, right: '3vw',
              }}>
                <PremioSorteio />
              </div>
              <div style={{
    position: 'absolute',
    top: 10,
    right: 0, // Posiciona a div no lado direito da tela
    width: '100vw',
    height: '65vh',
    backgroundSize: 'cover',
    margin: '0 auto',
    borderRadius: '0px',
    overflow: 'hidden'
}}>
    <div style={{
        position: 'absolute',
        zIndex: 1,
        backgroundImage: `url(${coutImageBoll})`,
        backgroundSize: 'cover',
        width: '150px',
        height: '150px',
        top: 0,
        right: 0, // Posiciona a div interna no lado direito
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <div style={{
            backgroundColor: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '60%',
            height: '60%'
        }}>
            <h1 style={{ margin: 0, fontSize: 80 }}>{quantidadeNumeros}</h1>
        </div>
    </div>
</div>

              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70vw',
                height: '65vh',
                backgroundSize: 'cover',
                margin: '0 auto',
                borderRadius: '0px',
                // overflow: 'hidden'
              }}>
                <ComponentBall />
              </div>
             
              <div style={{ width: '33vw', height: '40vh', backgroundColor: 'white', position: 'absolute', right: 10,
                 top: 'auto', // Use 'auto' para garantir que a posição vertical seja ajustada conforme necessário
                 display: 'flex', 
                 bottom: '30vh', 
                 justifyContent: 'flex-end'
              }}>
                <BolasSorteadas />
              </div>
              <div style={{ position: 'absolute', bottom: 1, right: 1, width: '34vw', height: '30vh',
                     top: 'auto', // Use 'auto' para garantir que a posição vertical seja ajustada conforme necessário
                     justifyContent: 'flex-end',
                     marginTop: '10px'
               }}>
                <UltimasBolas />
              </div>
            </>
          }
        </div>
      </div>

    </Container>
  );
}

export default Sorteio;
//156