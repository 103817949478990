// App.js
import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
    BrowserRouter,
    Routes, // instead of "Switch"
    Route,
  } from "react-router-dom";
import Sorteio from './pages/sorteio';
import { BingoProvider } from './context/BingoContext';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import PageNotFound from './pages/PageNotFound';

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'app-key',
    // wsHost: '127.0.0.1',
    // wsHost: 'localhost',
    wsHost: '154.38.183.33', 
    // wsHost: 'https://4r86v202-8000.brs.devtunnels.ms',
    wsPort: 6001,
    wssPort: 6001,
    forceTLS: false,
    encrypted: false,
    disableStats: true,
    // cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
    cluster: 'mt1',
    
});
console.log(`WebSocket URL: ws://${window.Echo.options.wsHost}:${window.Echo.options.wsPort}`);

window.Echo.connector.pusher.connection.bind('connected', () => {
  console.log('Conectado ao WebSocket!');
});

window.Echo.connector.pusher.connection.bind('disconnected', () => {
  console.log('Desconectado do WebSocket.');
});
window.Echo.channel('atualizar_rifa')
    .listen('AtualizarDados', (e) => {
        console.log('Dados recebidos do WebSocket:', e);
    });


const App = () => {
    return (
        <BingoProvider>
<BrowserRouter>
<Routes>
  <Route path="/" element={<Sorteio />} />
  <Route path="*" element={<PageNotFound />} />

</Routes>
</BrowserRouter>
</BingoProvider>
    );
}

export default App;
